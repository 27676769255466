import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <ProjectCard title="About me" link="https://www.linkedin.com/in/itsikye/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  This is my LinkedIn profile #LinkedIn
    </ProjectCard>
    <ProjectCard title="TikTok" link="https://www.tiktok.com/@itsikk?" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  I entered the DOCMA 2017 award with this Harry Potter inspired image.
    </ProjectCard>
    <ProjectCard title="Twitter" link="https://twitter.com/itsikye/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  This is my Twitter account
    </ProjectCard>
    <ProjectCard title="Instagram" link="https://www.instagram.com/itsiky/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A fantasy image manipulation relocating the habitat of wild animals.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      